import { FormQuestion, FormQuestionOption } from '../../Types/FormBuilder';
import { v4 as uuidv4 } from 'uuid';
import { supportedLocales } from '../../../I18n';

export const localizedAttributesFor = (attributeName: string, value = ''): any => {
  return supportedLocales.reduce(
    (acc, lang) => ({
      ...acc,
      [`${attributeName}_${lang}`]: value,
    }),
    {},
  );
};

export const generateFormQuestion = (): FormQuestion => ({
  field_type: 'text',
  required: false,
  newRecord: true,
  uuid_ref: uuidv4(),
  ...localizedAttributesFor('title'),
  ...localizedAttributesFor('description'),
  ...localizedAttributesFor('placeholder'),
});

export const generateQuestionOption = (other = false, value?: string): FormQuestionOption => ({
  other,
  newRecord: true,
  uuid_ref: uuidv4(),
  ...localizedAttributesFor('label', value),
});
