import { get, isEmpty } from 'lodash';

import PostTypes from '../../Types/Posts';
import { isSteppable } from '../../Types/Steps';
import {
  convertQuestionOptionIdsToArray,
  convertAttachmentsObjectsToArray,
  filterQuestionOptionIds,
} from '../../../Components/Posts/Show/Consultation/Tabs/Contents/FormShow/utils';

const generateRandomString = () => {
  return Math.random().toString(36).substring(2, 15);
};

export const isAdminGeneratedContent = (postType: any) => {
  const scheduleablePosts = [
    PostTypes.ARTICLE,
    PostTypes.IDEAS_REQUEST,
    PostTypes.PARTICIPATORY_BUDGET,
    PostTypes.COLLABORATIVE_DECISION,
    PostTypes.FOLDER,
    PostTypes.STUDY,
    PostTypes.EVENT,
  ];

  return scheduleablePosts.includes(postType);
};

function mapQuestions(questions: any[] | undefined) {
  return questions?.map((question, index) => ({
    ...question,
    position: index,
    question_options_attributes: mapOptions(question.question_options_attributes),
    question_links_attributes: removeEmptyQuestionLinks(question.question_links_attributes),
  }));
}

function removeEmptyQuestionLinks(questionLinks: any[] | undefined) {
  return questionLinks?.filter((questionLink) => !isEmpty(questionLink.target_ref));
}

function mapOptions(options: any[] | undefined) {
  return options?.map((option, optionIndex) => ({
    ...option,
    position: optionIndex,
  }));
}

export const generateData = (
  data: any,
  post: any,
  postType: any,
  space: any,
  questions: any = [],
  postRecordQuestions: any = [],
) => {
  const {
    tag_ids: tags,
    attachments,
    steps_attributes: stepsAttributes,
    application_file: applicationFile,
  } = data;
  const newAttachments = attachments?.map((attachment: any) => ({
    id: attachment.id,
    blob_id: attachment.blob_id,

    _destroy: attachment._destroy,
  }));
  // Autocomplete component returns an object if it's a single category and an array if it's multiple categories
  const tagIds = Array.isArray(tags) ? tags.map((tag) => tag.id) : [tags.id];
  const uniqueTagIds = [...new Set(tagIds)];

  // START: BUILD ATTACHMENTS LIST FOR THE QUESTIONS OF TYPE ATTACHMENTS
  // TODO: Make Attachemnts input set the blob id on the state instead of the object
  let answers;
  let formSubmissionsAttributes;
  let postRecordSubmissionsAttributes;

  if (questions.length > 0) {
    answers = get(data, 'form_submissions_attributes[0].answers_attributes', [])
      .map(convertQuestionOptionIdsToArray)
      .map(convertAttachmentsObjectsToArray)
      .map((answer: any) => filterQuestionOptionIds(answer, questions));

    formSubmissionsAttributes = [
      {
        ...get(data, 'form_submissions_attributes[0]', {}),
        answers_attributes: answers,
      },
    ];
  }

  if (postRecordQuestions.length > 0) {
    postRecordSubmissionsAttributes = [
      {
        ...get(data, 'post_record_submissions_attributes[0]', {}),
        answers_attributes: get(
          data,
          'post_record_submissions_attributes[0].answers_attributes',
          [],
        ),
      },
    ];
  }
  // END: BUILD ATTACHMENTS LIST FOR THE QUESTIONS OF TYPE ATTACHMENTS

  const reqBody = {
    ...data,
    id: post?.id,
    city_id: space.id,
    post_type: postType,
    tag_ids: uniqueTagIds || null,
    application_file: applicationFile?.blob_signed_id,
    attachments_attachments_attributes: newAttachments,
    // a hack to bypass uniqueness validation on `form_url` attribute
    form_url: data.form && !data.form_url ? generateRandomString() : data.form_url,
    form_submissions_attributes: formSubmissionsAttributes,
    post_record_submissions_attributes: postRecordSubmissionsAttributes,
    // Do not return `steps_attributes` for a non Steppable post type
    steps_attributes: isSteppable(postType) ? stepsAttributes : null,
    secured_strategy_questions_attributes: mapQuestions(data.secured_strategy_questions_attributes),
    form_questions_attributes: mapQuestions(data.form_questions_attributes),
  };

  return reqBody;
};
